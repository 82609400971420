import React from "react";
import { Navbar } from "react-bootstrap";
import siteBrandDark from "~image/logo/logo_black.svg";
import siteBrandLight from "~image/logo/logo_white.svg";

import Menu from "./Menu";
import useStoryblok from "../../../lib/storyblok";
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import styled from "styled-components/macro";
import { Box } from "~styled";

export const HeaderButtonWrapper = styled(Box)`
  .btn {
    min-width: auto;
    height: 42px;
    border-radius: 0px;
    background-color: #59D5B2;
    font-size: 16px;
    font-weight: 400;
    width: 150px;

    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 0.25px;
    color: white;
    margin-left: 10px;

    margin-right: 10px;
    &:hover {
      /* box-shadow: 0 0px 0px rgba(241, 139, 98, 0.3); */
    }
    &-2 {
      min-width: 129px;
      height: 42px;
      background-color: #f2f5fb;
      border-color: #f2f5fb;
    }
  }
`;


export const HeaderButtonWrapperBorder = styled(Box)`

.reveal-header .btn{
  color: #59D5B2 !important;
}
  .btn {
    height: 42px;
    border-radius: 0px;
    
    border: 2px solid #59D5B2;
    font-size: 16px;
    font-weight: 400;
    

    white-space: nowrap;
    line-height: 1.2;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    letter-spacing: 0.25px;
    color: white;
    margin-left: 10px;
    &:hover {
      /* box-shadow: 0 0px 0px rgba(241, 139, 98, 0.3); */
    }
    &-2 {
      min-width: 129px;
      height: 42px;
      background-color: #f2f5fb;
      border-color: #f2f5fb;
    }
  }
`;

const SiteNavbar = ({ buttonBlock, darkLogo, customLogo, defaultLogo }) => {
  const data = useStaticQuery(graphql`
  {
    storyblokEntry(full_slug: { eq: "header" }) {
      content
      name
    }
  
  
  }
    `)

  let story = data.storyblokEntry;

  story = useStoryblok(story);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="site-navbar"
      >
        <Link to="/">
        <Navbar.Brand>
          {defaultLogo ? (
            <img src={story.content.logo.filename} alt={story.content.logo.alt}  height="45px" />
          ) : customLogo ? (
            <img src={story.content.logo.filename} alt={story.content.logo.alt}  height="45px" />
          ) : (
            <img
              src={darkLogo ? story.content.logo.filename : story.content.logo.filename}
              alt="Care Aid Logo"
              height="45px"
            />
          )}
        </Navbar.Brand>

        </Link>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="hamburgur"
        />
        <Menu links={story.content.links}/>
        <HeaderButtonWrapperBorder className="ms-auto d-none d-xl-inline-flex">
      <Link className="btn other-link" to={story.content.button_1_link.cached_url}>
      {story.content.button_1_text}
      </Link>
    </HeaderButtonWrapperBorder>
    <HeaderButtonWrapperBorder       className="ms-auto d-none d-xl-inline-flex">
      <Link className="btn other-link" to={story.content.button_2_link.cached_url}>
      {story.content.button_2_text}
      </Link>
    </HeaderButtonWrapperBorder>

    <HeaderButtonWrapper  className="ms-auto d-none d-xs-inline-flex">
      <Link className="btn" to={story.content.button_3_link?.cached_url} target="_blank">
      {story.content.button_3_text}
      </Link>
    </HeaderButtonWrapper>

  

      </Navbar>
    </>
  );
};

export default SiteNavbar;
